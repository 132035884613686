<app-grid class="p-2">
  <div class="login-container">
    <mat-card>
      <div class="title">Login</div>
      <mat-card-content>
        <p>Please choose one of the following providers:</p>
        <p>
          Users with mail addresses ending on <b>ecvim-ca.college</b> should be able to use the
          <b>ECVIM-CA Cloud Login</b>. Others can use their existing credentials with <b>Legacy Login</b>.
        </p>
        <div class="button-group">
          <button id="button-cognito" mat-raised-button color="primary" (click)="login(AuthProvider.Cognito)">
            <mat-icon>login</mat-icon>
            Legacy Login
          </button>
          <button mat-raised-button (click)="login(AuthProvider.Nextcloud)">
            <img src="assets/images/Aeskulapstab.png" alt="ECVIM-CA Logo" title="ECVIM-CA Logo" />
            ECVIM-CA Cloud Login
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</app-grid>
